import React from 'react';
import Secure from './base_templates/Secure';
import Sms from './base_templates/Sms';
import Email from './base_templates/Email';

const Base = ({ mode, type, locale, handleChangeLocale, children, metadata }) =>
  type === 'Secure' ? (
    <Secure
      mode={mode}
      locale={locale}
      handleChangeLocale={handleChangeLocale}
      metadata={metadata}
    >
      {children}
    </Secure>
  ) : type === 'Email' ? (
    <Email mode={mode}>{children}</Email>
  ) : type === 'Sms' ? (
    <Sms>{children}</Sms>
  ) : (
    <div>{children}</div>
  );

export default Base;
